'use client';

import Navbar from '~components/layouts/navbar';

const AuthorizedLayout: NextAppLayout = ({ children }) => {
  return (
    <div className="w-full pb-[90px] sm:pb-0">
      <Navbar />
      {children}
    </div>
  );
};

export default AuthorizedLayout;
