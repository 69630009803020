import {
  ChangeWalletOutlined,
  CylindOutlined,
  DownArrowFilled,
  GiftBoxOutlined,
  WalletOutlined,
} from '@packages/icons-react';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'class-merge';
import { proxied } from 'radash';
import WalletBalanceWidget from '~components/widgets/wallet-balance-widget';
import { WalletEnum } from '~constants/etc';
import CurrencyWidget from '~components/widgets/currency-widget';

export const walletProps = proxied((type: WalletEnum) => {
  switch (type) {
    case WalletEnum.CREDIT:
      return {
        key: 'credit',
        text: 'credit-wallet',
        icon: <WalletOutlined className="text-xl" />,
        color: '#FF9B2C',
      };
    case WalletEnum.BONUS:
      return {
        key: 'bonus',
        text: 'bonus-wallet',
        icon: <GiftBoxOutlined className="text-xl" />,
        color: '#6255F7',
      };
    case WalletEnum.REVENUE:
      return {
        key: 'revenue',
        text: 'revenue-wallet',
        icon: <CylindOutlined className="text-xl" />,
        color: '#38BC85',
      };
  }
});

export const NavWalletContainer: FC<{
  walletType: string;
  handleSwitchWallet: () => void;
}> = ({ walletType, handleSwitchWallet }) => {
  return (
    <div className="relative inline-block h-[40px] text-left">
      <button
        className={cn(
          'bg-dark flex h-[40px] w-full items-center rounded-md p-1.5 text-white',
        )}
        onClick={handleSwitchWallet}
      >
        <div className="flex w-full min-w-[100px] items-center gap-x-2">
          <div className="flex w-full items-center gap-2">
            <div
              style={{
                color: walletProps[walletType].color,
              }}
            >
              {walletProps[walletType].icon}
            </div>
            <WalletBalanceWidget className="w-full text-right text-xs" />
          </div>
          <DownArrowFilled className="text-base" />
        </div>
      </button>
    </div>
  );
};

export const FinancialWalletWidget: FC<{
  walletType: string;
  handleSwitchWallet: () => void;
}> = ({ walletType, handleSwitchWallet }) => {
  const { t } = useTranslation(['wallets']);
  return (
    <div className="mb-4 flex flex-col gap-1">
      <div className="flex items-center justify-between">
        <p className="font-bold">{t('wallets:wallet-lists')}</p>
        <div
          aria-hidden
          className="flex items-center gap-2 text-sm"
          onClick={handleSwitchWallet}
          role="button"
        >
          <ChangeWalletOutlined className="text-success text-base" />
          <p>{t('wallets:switch-wallet')}</p>
        </div>
      </div>
      <div className="bg-secondary min-h-[100px] w-full rounded-2xl p-6">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <div
              className="flex h-[35px] w-[35px] flex-none items-center justify-center rounded-lg"
              style={{
                backgroundColor: walletProps[walletType].color,
              }}
            >
              {walletProps[walletType].icon}
            </div>
            <p className="text-lg">
              {t(`wallets:${walletProps[walletType]?.text}` as any)}
            </p>
          </div>
          <div className="flex-none space-x-2">
            <WalletBalanceWidget className="w-full text-3xl" />
            <CurrencyWidget className="font-semibold" />
          </div>
        </div>
      </div>
    </div>
  );
};
