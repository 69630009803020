import { ChangeWalletOutlined, CorrectOutlined } from '@packages/icons-react';
import { cn } from 'class-merge';
import { isNull, keys, omitBy } from 'lodash';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { walletProps } from './wallet-widget';
import type { WalletEnum } from '~constants/etc';
import { useWalletInfo } from '~hooks/use-wallet';
import BalanceWidget from '~components/widgets/balance-widget';

export const WalletBalance: FC<{
  className?: string;
  walletId: string;
}> = (props) => {
  const { className, walletId } = props;
  const { data: apiWallet, isLoading } = useWalletInfo(walletId);

  if (isLoading) {
    return (
      <span className={className} title="balance">
        ...
      </span>
    );
  }

  return <BalanceWidget balance={apiWallet?.balance} className={className} />;
};

const WalletListsWidget: FC<{
  activeWalletId: string;
  wallets: Record<WalletEnum, string> | undefined;
  onSelectWallet: (wallet: string) => void;
}> = (props) => {
  const { activeWalletId, wallets, onSelectWallet } = props;
  const { t } = useTranslation(['wallets', 'common']);

  return (
    <div className="bg-dark relative -top-56 w-full max-w-[350px] rounded-3xl p-4">
      <div className="mb-1 flex items-center gap-2 text-sm">
        <ChangeWalletOutlined className="text-success text-base" />
        <p className="text-lg font-semibold">{t('common:wallet')}</p>
      </div>
      <div className="mt-4 flex flex-col gap-2">
        {keys(omitBy(wallets, (v: string) => isNull(v))).map((res) => (
          <button
            className={cn(
              'hover:bg-oxford flex w-full items-center space-x-2 rounded-xl px-4 py-2 text-left',
              activeWalletId === wallets?.[res] && 'bg-oxford',
            )}
            key={res}
            onClick={() => {
              onSelectWallet(wallets?.[res]);
            }}
          >
            <div
              className="flex size-10 flex-none items-center justify-center rounded-xl"
              style={{
                backgroundColor: walletProps[res].color,
              }}
            >
              {walletProps[res].icon}
            </div>
            <div className="flex w-full flex-col">
              <WalletBalance
                className="text-xl font-bold"
                walletId={wallets?.[res]}
              />
              <p>{t(`wallets:${walletProps[res]?.text}` as any)}</p>
            </div>
            {activeWalletId === wallets?.[res] ? (
              <div className="bg-success relative h-6 min-w-6 rounded-full">
                <CorrectOutlined className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs" />
              </div>
            ) : null}
          </button>
        ))}
      </div>
    </div>
  );
};

export default WalletListsWidget;
