'use client';

import type { FC } from 'react';
import MenuBottom from '~containers/navbar/menu-bottom';
import MenuBar from '~containers/navbar/menubar';
import TopBar from '~containers/navbar/top-bar';
import useIsMobile from '~libs/use-is-mobile';

const Navbar: FC = () => {
  const isMobile = useIsMobile();

  return (
    <div className="relative z-10">
      <TopBar />
      {isMobile ? <MenuBottom /> : <MenuBar />}
    </div>
  );
};

export default Navbar;
