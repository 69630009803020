import {
  ChangeWalletOutlined,
  CorrectOutlined,
  DownArrowFilled,
  UserOutlined,
  WalletOutlined,
} from '@packages/icons-react';
import { cn } from 'class-merge';
import Image from 'next/image';
import numeral from 'numeral';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~components/button';
import { ButtonColors, SizeTypes } from '~constants/components';
import { MoneyFormat } from '~constants/etc';

interface Items {
  hidden?: boolean;
  name: string;
  icon: string | React.ReactNode;
  action: (() => void) | string;
  credit?: number | string;
  children?: Items[];
}

interface DynamicMenuProps {
  items: Items[];
  itemActive: string;
  onChange: (action: string) => void;
  isWallet?: boolean;
  isArrow?: boolean;
  activeClassName?: string;
  className?: string;
}

const DynamicMenu: React.FC<DynamicMenuProps> = ({
  items,
  itemActive,
  className,
  onChange,
  isWallet,
  isArrow = true,
  activeClassName = 'bg-evaluated',
}) => {
  const { t } = useTranslation('common');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        menuRef.current &&
        buttonRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
        setIsSubMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = useCallback((): void => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  const activeData = items.find((item) => item.action === itemActive);

  const handleItemClick = (action: (() => void) | string): void => {
    if (typeof action === 'function') {
      action();
    } else {
      onChange(action);
    }
    setIsMenuOpen(false);
    setIsSubMenuOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <Button
        className={cn('size-8 min-w-8 border-none p-0', className)}
        color={ButtonColors.Default}
        onClick={toggleMenu}
        ref={buttonRef}
        size={SizeTypes.Small}
      >
        {activeData?.icon ? (
          <div
            className={cn(
              isWallet && 'min-w-[100px]',
              'flex w-full items-center gap-x-2',
            )}
          >
            {!isWallet && typeof activeData.icon === 'string' ? (
              <Image
                alt={activeData.name}
                height={24}
                src={activeData.icon}
                width={24}
              />
            ) : (
              <div className="flex w-full items-center">
                <WalletOutlined className="text-primary text-base" />
                <div className="w-full text-right text-xs">
                  {numeral(activeData?.credit ?? 0).format(MoneyFormat)}
                </div>
              </div>
            )}
            {isArrow ? <DownArrowFilled className="text-base" /> : null}
          </div>
        ) : (
          <UserOutlined className="text-2xl text-white" />
        )}
      </Button>

      <div
        className={cn(
          isMenuOpen
            ? 'visible scale-100 opacity-100'
            : 'invisible scale-0 opacity-0',
          'transform transition-all duration-300',
          'bg-dark absolute right-0 z-20 mt-2.5 w-max rounded-2xl p-2.5 text-white shadow-lg md:mt-5',
        )}
        ref={menuRef}
      >
        {isWallet ? (
          <div className="mb-1 flex items-center gap-x-1 text-sm">
            <ChangeWalletOutlined className="text-success text-base" />
            <span>{t('wallet')}</span>
          </div>
        ) : null}

        {items.map((item) => {
          const isActive = item.action === itemActive;
          if (item.hidden) return null;
          return (
            <button
              className={cn(
                isActive && activeClassName,
                'hover:bg-oxford group relative flex w-full items-center gap-2 rounded-lg p-2 text-left',
              )}
              key={item.name}
              onClick={() => {
                if (item.children?.length) {
                  setIsSubMenuOpen((prev) => !prev);
                } else {
                  handleItemClick(item.action);
                }
              }}
            >
              {item.icon && typeof item.icon === 'string' ? (
                <Image alt={item.name} height={24} src={item.icon} width={24} />
              ) : (
                <span className="group-hover:text-primary text-gray text-2xl">
                  {item.icon}
                </span>
              )}
              <div className="flex w-full flex-col">
                <span className="min-w-24 text-left text-sm capitalize">
                  {item.credit
                    ? numeral(item.credit).format(MoneyFormat)
                    : item.name}
                </span>
                {item.credit ? (
                  <span
                    className={cn(
                      'text-light text-xs',
                      isActive && 'text-white',
                    )}
                  >
                    {item.name}
                  </span>
                ) : null}
              </div>
              {isActive && item.credit ? (
                <div className="bg-success relative h-4 min-w-4 rounded-full">
                  <CorrectOutlined className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[8px]" />
                </div>
              ) : null}

              {item.children?.length ? (
                <div
                  className={cn(
                    isSubMenuOpen
                      ? 'visible scale-100 opacity-100'
                      : 'invisible scale-0 opacity-0',
                    'transform transition-all duration-300',
                    'bg-oxford absolute right-full top-0 w-max -translate-x-5 rounded-2xl p-2.5',
                  )}
                >
                  {item.children.map((child, j) => {
                    return (
                      <button
                        className="hover:bg-gray flex w-full items-center gap-2 rounded-lg p-1.5"
                        key={j}
                        onClick={() => {
                          handleItemClick(child.action);
                        }}
                      >
                        {child.icon && typeof child.icon === 'string' ? (
                          <Image
                            alt={child.name}
                            className="flex-shrink-0"
                            height={24}
                            src={child.icon}
                            width={24}
                          />
                        ) : (
                          <span className="text-gray text-2xl">
                            {child.icon}
                          </span>
                        )}
                        <span className="text-sm font-bold">{child.name}</span>
                      </button>
                    );
                  })}
                </div>
              ) : null}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DynamicMenu;
