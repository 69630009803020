import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { cn } from 'class-merge';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';
import PageUrls from '~constants/page-urls';

const UnauthorizedNavigation: FC<{
  className?: string;
}> = ({ className }) => {
  const { t } = useTranslation('common');

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Button
        className="bg-dark w-auto md:w-[180px]"
        color={ButtonColors.Transparent}
        href={PageUrls.Login}
      >
        {t('login')}
      </Button>
      <Button
        className="w-auto md:w-[180px]"
        color={ButtonColors.Primary}
        href={PageUrls.Register}
      >
        {t('register')}
      </Button>
    </div>
  );
};

export default UnauthorizedNavigation;
