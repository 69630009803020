import { proxied } from 'radash';
import type { FC } from 'react';
import React from 'react';
import { WalletWidgetTypeEnum, WalletEnum } from '~constants/etc';
import { useDialog } from '~hooks/use-dialog';
import { useWalletInfo } from '~hooks/use-wallet';
import WalletListsWidget from '~components/widgets/wallet-lists-widget';
import { ClientSideRender } from '~components/layouts/client-side-render';
import {
  FinancialWalletWidget,
  NavWalletContainer,
} from '~components/widgets/wallet-widget';

interface WalletWidgetProps {
  type: WalletWidgetTypeEnum;
  wallets: Record<WalletEnum, string> | undefined;
  activeWalletId: string;
  handleSwitchWallet: (wallet: string) => void;
}

const WalletContainer: FC<WalletWidgetProps> = (props) => {
  const { type, activeWalletId, wallets, handleSwitchWallet } = props;
  const { data: walletInfo } = useWalletInfo(activeWalletId, {
    suspense: true,
  });
  const [dialog, dialogContext] = useDialog();
  const walletType = walletInfo?.type || WalletEnum.CREDIT;

  const handleSelectWallet = (value: string): void => {
    dialog.destroy();
    handleSwitchWallet(value);
  };

  const handleChangeWallet = (): void => {
    dialog.content({
      maskable: true,
      children: (
        <ClientSideRender>
          <WalletListsWidget
            activeWalletId={activeWalletId}
            onSelectWallet={(wallet: string) => {
              handleSelectWallet(wallet);
            }}
            wallets={wallets}
          />
        </ClientSideRender>
      ),
    });
  };

  const walletContents = proxied((prop: WalletWidgetTypeEnum) => {
    switch (prop) {
      case WalletWidgetTypeEnum.Navigation:
        return (
          <NavWalletContainer
            handleSwitchWallet={handleChangeWallet}
            walletType={walletType}
          />
        );
      case WalletWidgetTypeEnum.Financial:
        return (
          <FinancialWalletWidget
            handleSwitchWallet={handleChangeWallet}
            walletType={walletType}
          />
        );
      default:
        return <div />;
    }
  });

  return (
    <>
      {dialogContext}
      {walletContents[type]}
    </>
  );
};

export default WalletContainer;
